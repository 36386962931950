export const children = [
    {
        path: '/system/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard/index.vue')
    },
    {
        path: '/system/kycValidation',
        name: 'KYCValidation',
        component: () => import('@/views/KYCValidation/index.vue')
    },
    {
        path: '/system/subAcc',
        name: 'SubAcc',
        component: () => import('@/views/SubAcc/index.vue')
    },
    {
        path: '/system/admin',
        name: 'admin',
        component: () => import('@/views/FinancialManagement/Admin/index.vue')
    },
    {
        path: '/system/RentIncome',
        name: 'RentIncome',
        component: () => import('@/views/FinancialManagement/RentIncome/index.vue')
    },
    {
        path: '/system/RechargeOrders',
        name: 'RechargeOrders',
        component: () => import('@/views/FinancialManagement/RechargeOrders/index.vue')
    },
    {
        path: '/system/ConsumptionOrders',
        name: 'ConsumptionOrders',
        component: () => import('@/views/FinancialManagement/ConsumptionOrders/index.vue')
    },
    {
        path: '/system/dividend',
        name: 'Dividend',
        component: () => import('@/views/FinancialManagement/Dividend/index.vue')
    },
    {
        path: '/system/user',
        name: 'Users',
        component: () => import('@/views/UsersManagement/UserList/index.vue')
    },
    {
        path: '/system/userInfo',
        name: 'userInfo',
        component: () => import('@/views/UsersManagement/UserInfo/index.vue')
    },
    {
        path: '/system/CouponList',
        name: 'CouponList',
        component: () => import('@/views/Coupon/CouponList')
    },
    {
        path: '/system/IssuedCoupon',
        name: 'IssuedCoupon',
        component: () => import('@/views/Coupon/IssuedCoupon')
    },
    {
        path: '/system/WithdrawalSummary',
        name: 'WithdrawalSummary',
        component: () => import('@/views/FinancialManagement/WithdrawalSummary/index.vue')
    },
    {
        path: '/system/Frame',
        name: 'Frame',
        component: () => import('@/views/ItemsManagement/Frame')
    },
    {
        path: '/system/Airdrop',
        name: 'Airdrop',
        component: () => import('@/views/ItemsManagement/Airdrop')
    },
    {
        path: '/system/items',
        name: 'Items',
        component: () => import('@/views/ItemsManagement/Items')
    },
    {
        path: '/system/Ambassador',
        name: 'Ambassador',
        component: () => import('@/views/Ambassador')
    },
    {
        path: '/system/GameOperator',
        name: 'GameOperator',
        component: () => import('@/views/CaaS/GameOperator')
    },
    {
        path: '/system/GameOperatorInfo',
        name: 'GameOperatorInfo',
        component: () => import('@/views/CaaS/GameOperator/GOInfo/GOInfo.vue')
    },
    {
        path: '/system/Audit',
        name: 'Audit',
        component: () => import('@/views//CaaS/Audit')
    },
    {
        path: '/system/FomoSale',
        name: 'FomoSale',
        component: () => import('@/views/Event/FomoSale/index.vue')
    },
    {
        path: '/system/PNFT/:PNFTName',
        name: 'PNFT',
        component: () => import('@/views/FaaSManagement/PNFTFragment/index.vue'),
    },
    {
        path: '/system/RNFT',
        name: 'rnft',
        component: () => import('@/views/FaaSManagement/RNFT/SeriesTable/index.vue'),
    },
    {
        path: '/system/RNFT-tier',
        name: 'rnftTier',
        component: () => import('@/views/FaaSManagement/RNFT/TierTable/index.vue'),
    },
    {
        path: '/system/rnft-blindbox-tier',
        name: 'rnftBlindboxTier',
        component: () => import('@/views/FaaSManagement/RNFT/Blindbox/index.vue'),
    },
    {
        path: '/system/rnft-blindbox-config',
        name: 'rnftBlindboxConfig',
        component: () => import('@/views/FaaSManagement/RNFT/BlindboxConfig/index.vue'),
    },
    {
        path: '/system/announcement',
        name: 'announcement',
        component: () => import('@/views/Announcement/index.vue'),
    },
]
